<template>
    <v-dialog v-model="isOpen" width="500">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Créer une actualité
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider />

            <v-card-text class="pt-4">
                <p class="text--secondary">
                    Saisissez tout d'abord le titre et le contenu de l'actualité.<br>La page suivante permettra de préciser les modalités de diffusion de l'actualité et de compléter l'affichage avec des photos ou des documents attachés.
                </p>

                <v-text-field v-model="title" label="Titre" class="mb-4" hide-details outlined dense />

                <quill-editor v-model="content" class="mb-0" />
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" color="primary" small>
                    Suivant
                </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small>
                    Annuler
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ActualityCreationDialog',

    data: () => ({
        isOpen: false,
        title: '',
        content: ''
    }),

    methods: {
        async submit() {
            try {
                this.setLoading(true);

                const body = {
                    actuality: {
                        title: this.title,
                        content: this.content
                    }
                };

                const { success, actualityId, err } = await this.repos.actualities.createActuality(body);
                if (success) {
                    this.isOpen = false;
                    this.$notify({
                        title: 'Information',
                        text: 'L\'actualité a bien été créée',
                        type: 'success'
                    });
                    this.$emit('created', { actualityId });
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la création de l\'actualité',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    },

    watch: {
        isOpen() {
            this.title = '';
            this.content = '';
        }
    }
};
</script>
