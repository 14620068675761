<template>
    <v-container>
        <v-row>
            <v-col class="pt-0">
                <h2 class="primary--text"> Actualités </h2>
            </v-col>
        </v-row>

        <!-- Quick search and pagination -->
        <div class="d-flex align-center justify-space-between mb-2">
            <div class="d-flex align-center">
                <v-text-field v-model="quickSearch" @keydown.enter="fetchActualities(true)" label="Recherche..." dense hide-details outlined clearable>
                    <template v-slot:append>
                        <v-icon color="grey" @click="fetchActualities(true)"> fas fa-search </v-icon>
                    </template>
                </v-text-field>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn small text class="grey lighten-2 ml-4" v-on="on">
                            {{ actualitiesCount }}
                        </v-btn>
                    </template>
                    Nombre total d'actualités
                </v-tooltip>

                <ActualityCreationDialog v-if="allowEdition" @created="onActualityCreated">
                    <template v-slot:activator="{ on: dialog }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn class="ml-4" color="primary" small v-on="{ ...dialog, ...tooltip }">
                                    Ajouter
                                </v-btn>
                            </template>
                            Créer une nouvelle actualité
                        </v-tooltip>
                    </template>
                </ActualityCreationDialog>

                <v-switch v-if="allowEdition" v-model="visibleOnlyFilter" class="shrink pt-0 mt-0 ml-4 mr-2" label="Actualités diffusées seulement" dense hide-details />

                <ProgramAutocomplete ref="programAutocomplete" v-model="programsFilter" label="Programmes" noDynamicSearch multiple />
            </div>

            <div class="d-flex align-center">
                <v-select v-if="allowEdition" v-model="order" class="d-inline-flex" label="Trier par" :menu-props="{ bottom: true, offsetY: true }" :items="actualitiesSortItems" background-color="white" @change="fetchActualities(true)" hide-details outlined dense />

                <v-tooltip v-if="allowEdition" top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="toggleOrder()" icon v-on="on" class="ml-2">
                            <v-icon v-bind:class="{'fa-rotate-180': by === 'asc'}">
                                fas {{ by === 'asc' ? 'fa-sort-amount-down-alt': 'fa-sort-amount-down' }}
                            </v-icon>
                        </v-btn>
                    </template>
                    {{ by === 'asc' ? 'Croissant' : 'Décroissant' }}
                </v-tooltip>

                <v-pagination v-model="page" @input="fetchActualities(true, false)" :length="totalPages" :total-visible="7" color="primary" />
            </div>
        </div>

        <v-row v-if="actualities.length > 0">
            <v-col cols="12" lg="4" xl="3" v-for="actuality of actualities" :key="actuality.id" style="max-height: 400px">
                <ActualityCard :ref="`actuality-${actuality.id}`" :initActuality="actuality" imageHeight="150px" :allowModification="allowEdition" @updated="fetchActualities(false, false)" @deleted="fetchActualities(false, false)" />
            </v-col>
        </v-row>

        <v-row v-if="actualities.length === 0">
            <v-col class="text-center">
                <h1 class="font-weight-regular">
                    Aucune Actualité
                </h1>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import actualitiesMixin from '../mixins/actualities.js';
import ActualityCard from '../components/actualities/ActualityCard.vue';
import ProgramAutocomplete from '../components/widgets/ProgramAutocomplete.vue';
import ActualityCreationDialog from '../components/actualities/ActualityCreationDialog.vue';

export default {
    name: 'Actualities',

    mixins: [actualitiesMixin],

    components: {
        ActualityCard,
        ProgramAutocomplete,
        ActualityCreationDialog
    },

    data: () => ({
        displayAdvanceSearch: false,

        quickSearch: null,
        programsFilter: [],
        visibleOnlyFilter: false,

        page: 1,
        limit: 10,
        order: 'created',
        by: 'desc',

        resetPage: false, // wether or not to reset the page to 1 when fetching the next items

        actualities: [],
        actualitiesCount: 0,
        totalPages: 0,
        disableNavigation: false,
        actualitiesSortItems: [
            { text: 'Date de création', value: 'created' },
            { text: 'Fin de diffusion', value: 'endDate' },
            { text: 'Début de diffusion', value: 'startDate' }
        ]
    }),

    methods: {
        buildQuery() {
            const query = {
                page: this.page,
                limit: this.limit,
                orderBy: `${this.order}:${this.by}`,
                include: 'attachments,targetCodes'
            };
            if (this.quickSearch) {
                query.quickSearch = this.quickSearch;
            }
            if (this.programsFilter.length > 0) {
                query.programs = this.programsFilter.join(',');
            }
            if (this.visibleOnlyFilter) {
                query.enableDateRange = '1';
                query.publish = '1';
            }
            return query;
        },

        buildDisplayQuery() {
            const query = {};
            if (this.page !== 1) {
                query.page = this.page;
            }
            if (this.quickSearch) {
                query.quickSearch = this.quickSearch;
            }
            if (this.programsFilter.length > 0) {
                query.programs = this.programsFilter.join(',');
            }
            if (this.order !== 'program.title') {
                query.order = this.order;
                query.by = this.by;
            }
            return query;
        },

        parseQuery(query) {
            this.page = parseInt(query.page) || 1;
            this.limit = parseInt(query.limit) || 10;
            this.quickSearch = query.quickSearch || null;
            this.order = query.order ? query.order : this.order;
            this.by = query.by ? query.by : this.by;
            this.programsFilter = query.programs ? query.programs.split(',').map((p) => parseInt(p)) : [];
        },

        doPush(query) {
            return (Object.keys(query).length !== Object.keys(this.$route.query).length) || !Object.keys(query).every((key) => query[key] === this.$route.query[key]);
        },

        async fetchActualities(push, resetPage = true, actualityId = null) {
            try {
                this.disableNavigation = true;
                this.setLoading(true);

                if (resetPage) {
                    this.page = 1;
                }

                if (push && this.doPush(this.buildDisplayQuery())) {
                    this.$router.push({ query: this.buildDisplayQuery() });
                }

                const query = this.buildQuery();
                const { actualities, count, totalPages, err } = await this.repos.actualities.getActualities(query);
                if (err) {
                    throw new Error(err);
                } else {
                    this.actualities = actualities;
                    this.actualitiesCount = count;
                    this.totalPages = totalPages;

                    if (actualityId) {
                        this.$nextTick(() => {
                            const key = `actuality-${actualityId}`;
                            this.$refs[key][0].openEditionDialog();
                        });
                    }
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du rechargement de la page',
                    type: 'error'
                });
            } finally {
                this.disableNavigation = false;
                this.setLoading(false);
            }
        },

        toggleOrder() {
            this.by = this.by === 'asc' ? 'desc' : 'asc';
            this.fetchActualities(true);
        },

        onActualityCreated(data) {
            this.page = 1;
            this.fetchActualities(false, false, data.actualityId);
        }
    },

    computed: {
        allowEdition() {
            return this.$store.state.application.user.type === 'user';
        }
    },

    watch: {
        $route(to) {
            if (!this.disableNavigation) {
                this.parseQuery(to.query);
                this.fetchActualities(false);
            }
        },

        visibleOnlyFilter() {
            this.fetchActualities(false, false);
        },

        programsFilter() {
            this.fetchActualities(false, false);
        }
    },

    created() {
        this.parseQuery(this.$route.query);
        this.$nextTick(() => {
            this.$refs.programAutocomplete.fetchPrograms({ limit: 10000 });
        });
    }
};
</script>
