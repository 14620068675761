var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('h2',{staticClass:"primary--text"},[_vm._v(" Actualités ")])])],1),_c('div',{staticClass:"d-flex align-center justify-space-between mb-2"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-text-field',{attrs:{"label":"Recherche...","dense":"","hide-details":"","outlined":"","clearable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchActualities(true)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"grey"},on:{"click":function($event){return _vm.fetchActualities(true)}}},[_vm._v(" fas fa-search ")])]},proxy:true}]),model:{value:(_vm.quickSearch),callback:function ($$v) {_vm.quickSearch=$$v},expression:"quickSearch"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"grey lighten-2 ml-4",attrs:{"small":"","text":""}},on),[_vm._v(" "+_vm._s(_vm.actualitiesCount)+" ")])]}}])},[_vm._v(" Nombre total d'actualités ")]),(_vm.allowEdition)?_c('ActualityCreationDialog',{on:{"created":_vm.onActualityCreated},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-4",attrs:{"color":"primary","small":""}},Object.assign({}, dialog, tooltip)),[_vm._v(" Ajouter ")])]}}],null,true)},[_vm._v(" Créer une nouvelle actualité ")])]}}],null,false,1935881608)}):_vm._e(),(_vm.allowEdition)?_c('v-switch',{staticClass:"shrink pt-0 mt-0 ml-4 mr-2",attrs:{"label":"Actualités diffusées seulement","dense":"","hide-details":""},model:{value:(_vm.visibleOnlyFilter),callback:function ($$v) {_vm.visibleOnlyFilter=$$v},expression:"visibleOnlyFilter"}}):_vm._e(),_c('ProgramAutocomplete',{ref:"programAutocomplete",attrs:{"label":"Programmes","noDynamicSearch":"","multiple":""},model:{value:(_vm.programsFilter),callback:function ($$v) {_vm.programsFilter=$$v},expression:"programsFilter"}})],1),_c('div',{staticClass:"d-flex align-center"},[(_vm.allowEdition)?_c('v-select',{staticClass:"d-inline-flex",attrs:{"label":"Trier par","menu-props":{ bottom: true, offsetY: true },"items":_vm.actualitiesSortItems,"background-color":"white","hide-details":"","outlined":"","dense":""},on:{"change":function($event){return _vm.fetchActualities(true)}},model:{value:(_vm.order),callback:function ($$v) {_vm.order=$$v},expression:"order"}}):_vm._e(),(_vm.allowEdition)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":""},on:{"click":function($event){return _vm.toggleOrder()}}},on),[_c('v-icon',{class:{'fa-rotate-180': _vm.by === 'asc'}},[_vm._v(" fas "+_vm._s(_vm.by === 'asc' ? 'fa-sort-amount-down-alt': 'fa-sort-amount-down')+" ")])],1)]}}],null,false,1759518790)},[_vm._v(" "+_vm._s(_vm.by === 'asc' ? 'Croissant' : 'Décroissant')+" ")]):_vm._e(),_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":7,"color":"primary"},on:{"input":function($event){return _vm.fetchActualities(true, false)}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]),(_vm.actualities.length > 0)?_c('v-row',_vm._l((_vm.actualities),function(actuality){return _c('v-col',{key:actuality.id,staticStyle:{"max-height":"400px"},attrs:{"cols":"12","lg":"4","xl":"3"}},[_c('ActualityCard',{ref:("actuality-" + (actuality.id)),refInFor:true,attrs:{"initActuality":actuality,"imageHeight":"150px","allowModification":_vm.allowEdition},on:{"updated":function($event){return _vm.fetchActualities(false, false)},"deleted":function($event){return _vm.fetchActualities(false, false)}}})],1)}),1):_vm._e(),(_vm.actualities.length === 0)?_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('h1',{staticClass:"font-weight-regular"},[_vm._v(" Aucune Actualité ")])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }